// vant 组件以及样式
import {
  Button,
  Toast,
  Loading,
  PullRefresh,
  Popup,
  List,
  Field,
  Cell,
  CellGroup,
  Search,
  NavBar,
  CountDown,
  Dialog,
  Notify,
  ActionSheet,
  Divider,
  Overlay,
  Image,
  Tabs,
  Tab,
  Icon,
  NoticeBar,
  Tag,
  Collapse,
  CollapseItem,
  Grid,
  GridItem,
  Swipe,
  SwipeItem,
  Badge,
  Area,
} from 'vant'
import 'vant/lib/index.css'

export default app => {
  app.use(Button)
  app.use(Toast)
  app.use(NavBar)
  app.use(Loading)
  app.use(PullRefresh)
  app.use(Popup)
  app.use(List)
  app.use(Field)
  app.use(Cell)
  app.use(CellGroup)
  app.use(Search)
  app.use(CountDown)
  app.use(Notify)
  app.use(Dialog)
  app.use(ActionSheet)
  app.use(Divider)
  app.use(Overlay)
  app.use(Image)
  app.use(Tabs)
  app.use(Tab)
  app.use(Icon)
  app.use(NoticeBar)
  app.use(Tag)
  app.use(Collapse)
  app.use(CollapseItem)
  app.use(Grid)
  app.use(GridItem)
  app.use(Swipe)
  app.use(SwipeItem)
  app.use(Badge)
  app.use(Area)
}
